import React, { useState, useEffect } from 'react';
import Modal from './Modal.js';
import logo from '../assets/full.jpg';
import es from '../assets/es.png'
import tg from '../assets/tg.png'
import tw from '../assets/tw.png'
import db from '../assets/db.png'
import dx from '../assets/dx.png'
import gif from '../assets/gif.gif'
import vid from '../assets/video.gif';
import eth from '../assets/eth.png'
import audioFile from '../assets/audio.mp3';
import '../App.css';
import links from '../links.json';

function App() {
  const [modalVisible, setModalVisible] = useState(true);
  const [audioPlaying, setAudioPlaying] = useState(false);

  useEffect(() => {
    setModalVisible(true);
  }, []);

  const closeModal = () => {
    setModalVisible(false);
    setAudioPlaying(true);
  };

  return (
    <div className="App">
      <div className='bounce'>
        <div className="el-wrap x">
          <img className="el y no-pointer" src={gif} alt='rock'></img>
        </div>
      </div>
      <div className='bounce1'>
        <div className="le-wrap w">
          <img className="le z no-pointer" src={eth} alt='eth'></img>
        </div>
      </div>
      <body>
      <div className='welcome'>
        <Modal show={modalVisible} onClose={closeModal} />
      </div>
      {audioPlaying && (
      <audio autoPlay loop>
        <source src={audioFile} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      )}
      <h1 className='top front bold'>очиститься</h1>
        <div className='container front'>
          <div className='card' >
            <img src={logo} className="App-logo no-pointer" alt="logo" width='500vw'/>
          </div>
        </div>
        <h1 className='top front'>HE IS REAL.</h1>
        <header className="App-header">
        <div className='container front'>
          <div className='item'>
            <a href={`https://etherscan.io/token/${links.contractAddress}`} target='_blank' rel="noreferrer"><img alt='es' src={es} width='120px'/></a>
          </div>
          <div className='item'>
            <a href={links.telegram} target='_blank' rel="noreferrer"><img alt='tg' src={tg} width='120px'/></a>
          </div>
          <div className='item'>
            <a href={links.twitter} target='_blank' rel="noreferrer"><img alt='tg' src={tw} width='120px'/></a>
          </div>
          <div className='item'>
            <a href={`https://www.dextools.io/app/en/ether/pair-explorer/${links.pair}`} target='_blank' rel="noreferrer"><img alt='dx' src={dx} width='105px'/></a>
          </div>
        </div>
      </header>
        <h1 className='bottom front bold'>очиститься, Real Banana Bird</h1>
        <div className='space'/>
        <h1 className='bottom front'>HE IS ON HIS WAY</h1>
        <img className='no-pointer' alt='td' src={db} width='350vw' style={{marginTop: '50px', marginBottom: '50px'}} className='front'/>
        <h1 className='bottom front'>OCHISTIT'SYA, only smart yellow banana bird</h1>
        <div className='card App-logo front' width='100%'>
          <img className='no-pointer' src={vid} alt='video' style={{width: '420px', borderRadius: '15px'}} /> 
        </div>
        <h1 className='top front'>смешной мем (funy)</h1>
        <p className='title front'>ZERO TAX, CONTRACT RENOUNCED, LIQUIDITY BURNED FOREVER. <br/>100% OF SUPPLY SENT TO UNISWAP & LP BURNT<br/> true community token</p>
      </body>
    </div>
  );
}

export default App;
